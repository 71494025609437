// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

async function initializeFirebase() {
    const response = await fetch('/json/config.json');
    const configData = await response.json();

    const firebaseConfig = configData.firebase;

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return { app,analytics };
}

export default initializeFirebase;
