import { createApp } from 'vue';
import App from './App.vue';
import initializeFirebase from './utils/firebase';


// Import ionicons
import 'ionicons/dist/ionicons/ionicons.esm.js';
import 'ionicons/dist/ionicons/ionicons.js';

// Initialize Firebase and then mount the app
initializeFirebase().then(() => {
    console.log("Firebase successfully initialized");

    createApp(App).mount('#app');
}).catch(error => {
    console.error("Error initializing Firebase:", error);
});
