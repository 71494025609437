<template>
    <article class="about">
        <header>
            <h2 class="h2 article-title">About me</h2>
        </header>
        <section class="about-text">
            <p id="about">{{ aboutText }}</p>
        </section>
        <section class="service">
            <h3 class="h3 service-title">What I'm doing</h3>
            <ul class="service-list" id="services-list">
                <li v-for="service in services" :key="service.type" class="service-item">
                    <div class="service-icon-box">
                        <img :src="require('@/assets/icon-dev.svg')" width="40">
                    </div>
                    <div class="service-content-box">
                        <h4 class="h4 service-item-title">{{ service.type }}</h4>
                        <p class="service-item-text">
                            {{ service.description }}
                        </p>
                    </div>
                </li>

            </ul>
        </section>
        <section class="clients">
            <h3 class="h3 clients-title">Tech Skills and Project Tech Stack Overview</h3>
            <ul class="clients-list has-scrollbar" id="techSkills">
                <li class="clients-item" v-for="skill in techSkills" :key="skill">
                    <a :href="skill.href" target="_blank">
                        <img :src="getImagePathTech(skill.imgSrc)" :alt="skill.altText">
                        <span class="client-title">{{ skill.title }}</span>
                    </a>
                </li>

            </ul>
        </section>
    </article>
</template>

<script>
import { getImagePathTech } from '@/utils/imageLoader.js';

export default {
    props: {
        aboutText: String,
        services: Array,
        techSkills: Array
    },
    methods: {
        getImagePathTech
    }
}
</script>

<style scoped>
/* Add styles for About section here */
</style>
