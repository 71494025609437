// Function to get the image path from a given filename
export function getImagePathTech(imgPath) {
    try {
        return require(`@/assets/images/tech/${imgPath}`);
    } catch (error) {
        console.error('Error loading image:', error);
        return ''; // Return a placeholder or empty string if image loading fails
    }
}

// Function to get the image path from a given filename
export function getImagePathCompany(imgPath) {
    try {
        return require(`@/assets/images/company/${imgPath}`);
    } catch (error) {
        console.error('Error loading image:', error);
        return ''; // Return a placeholder or empty string if image loading fails
    }
}

export function getImagePathWJ(imgPath) {
    try {
        return require(`@/assets/images/wj/${imgPath}`);
    } catch (error) {
        console.error('Error loading image:', error);
        return ''; // Return a placeholder or empty string if image loading fails
    }
}
