<template>
    <article class="about">

        <header>
            <h2 class="h2 article-title">Playground/Project/Tools</h2>
        </header>
        <section class="service">
            <h3 class="h3 service-title"></h3>
            <ul class="service-list" id="services-list">

                <li class="service-item">
                    <div class="service-content-box">
                        <h4 class="h4 service-item-title">Comming soon...</h4>
                        <p class="service-item-text">
                            Loading..
                        </p>
                    </div>
                </li>

                <li class="service-item">
                    <div class="service-content-box">
                        <h4 class="h4 service-item-title">Comming soon..</h4>
                        <p class="service-item-text">
                            Loading..
                        </p>
                    </div>
                </li>

                <li class="service-item">
                    <div class="service-content-box">
                        <h4 class="h4 service-item-title">Comming soon..</h4>
                        <p class="service-item-text">
                            Loading..
                        </p>
                    </div>
                </li>

                <li class="service-item">
                    <div class="service-content-box">
                        <h4 class="h4 service-item-title">Comming soon..</h4>
                        <p class="service-item-text">
                            Loading..
                        </p>
                    </div>
                </li>
            </ul>
        </section>
    </article>
</template>

<script>
import { getImagePathTech } from '@/utils/imageLoader.js';

export default {
    props: {

    },
    methods: {
        getImagePathTech
    }
}
</script>

<style scoped>
/* Add styles for About section here */
</style>
