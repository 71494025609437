<template>
  <div id="app">
    <MainView />
  </div>
</template>

<script>
import MainView from './components/MainView.vue'; // Adjust the path as necessary

export default {
  name: 'App',
  components: {
    MainView
  }
}
</script>

<style>
/* Global styles or App-specific styles can be placed here */
</style>
